@use "../../styles/mixins" as *;
@include layer(ui) {
    .grid {
        --grid-column-count: 1;
        --grid-column-responsive: var(--grid-mobile-column-count, 1);
        display: flex;
        flex-wrap: wrap;

        @include breakpoint-xs {
            gap: var(--grid-gap-xs);
        }
        @include breakpoint-sm {
            gap: var(--grid-gap-sm);
        }
        @include breakpoint-md {
            gap: var(--grid-gap-md);
        }
        @include breakpoint-lg {
            gap: var(--grid-gap-lg);
        }
        @include breakpoint-xl {
            gap: var(--grid-gap-xl);
        }

        &--left {
            justify-content: flex-start;
        }

        &--center {
            justify-content: center;
        }

        &--right {
            justify-content: flex-end;
        }

        & > * {
            display: grid; // this makes the children in the row the same height regardless of content
            /* mimic grid but uses flex to let authors control positioning of last row */
            flex-basis: calc(
                (
                        100% - var(--g-spacing-xl) *
                            (var(--grid-column-responsive) - 1)
                    ) / var(--grid-column-responsive)
            );
            max-width: 100%;
        }

        @include breakpoint-md {
            --grid-column-responsive: min(
                var(--grid-column-count),
                var(--grid-tablet-column-count, 2)
            );
        }

        @include breakpoint-lg {
            --grid-column-responsive: var(--grid-column-count);
        }
    }
}
